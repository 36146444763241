<template xmlns="http://www.w3.org/1999/html">
	<div class="main-content">
    <div class="title">请绑定身份信息</div>
    <van-form @submit="onSubmit">
      <van-field required
          v-model="id_number"
          name="身份证"
          label="身份证"
          :rules="[{ required: true, message: '请填写身份证' }]"
      />
      <van-field required
          v-model="qy_phone"
          name="企微手机号"
          label="企微手机号"
          :rules="[{ required: false, message: '请填写企微手机号' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <div class="desc">
      <div class="desc-title">备注：</div>
      1. 只有绑定身份的用户才能获取到信息；</br>
      2. 请填写企微账号设置的手机号；
    </div>
	</div>
</template>

<script>

import {Toast} from "_vant@2.12.53@vant";
import {getEwxUserInfo} from '@/api/test'

import {
  bind
} from '@/api/test'
import {gotoEnterPath} from "../../utils/base";

export default {
  data() {
    return {
      userInfo: null,
      id_number: '',
      qy_phone: '',
      rand: Math.random(),
    };
  },
  mounted() {

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    // 获取用户信息
    if (!this.userInfo || !this.userInfo.name) {
      this.getEwxUserInfo();
    } else {
      // this.$router.go(-1);
      gotoEnterPath(this.$router);
    }
  },
  methods: {
    async getEwxUserInfo(){
      const res = await getEwxUserInfo();
      if(res.code == 200){
        localStorage.setItem("userInfo", JSON.stringify(res.data.user_info));
        this.userInfo = res.data.user_info;

        console.log('this.userInfo.length', this.userInfo.length);

        if (this.userInfo.name) {
          // this.$router.go(-1);
          gotoEnterPath(this.$router);
        }
      }
    },
    async onSubmit(values) {

      console.log('submit', values);

      await bind({
        id_number:this.id_number,
        qy_phone:this.qy_phone
      }).then((res) =>{
        console.log(res,"result")
        if(res.code == 200){
          Toast.success('绑定成功');

          // 绑定后获取用户信息
          this.getEwxUserInfo();

        }else{
          Toast.fail(res.message)
        }
      });
    },
  },
};
</script>

<style>
.main-content .title{
  font-size: large;
  font-weight: bold;
  margin: 10px auto;
  text-align: center;
}
.main-content .desc {
  color: #7F7F7F;
  line-height: 2em;
}
.main-content .desc .desc-title {
  color: red;
  font-weight: bold;
}
</style>